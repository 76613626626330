.center-hrz{
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--col{
        display: flex;
        flex-direction: column;
        align-items: center;  
    }
    &-small{
        @include respond(tab-port){
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}


.end-hrz{
    display: flex;
    justify-content: flex-end;
}

