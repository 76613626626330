.modal{
    // z-index: 1000;
    position: fixed;
    width: 80%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 10px;
    transition: all 0.5s;
    padding: 3rem 2rem;
    z-index: 8000;
    max-height: 80vh;
    overflow-y: scroll;
    // @include respond(tab-port){
    //     z-index: 80000;

    //  }
}

.modal.active{
    transform: translate(-50%, -50%) scale(1);
    // @include respond(tab-port){
    //     z-index: 80000;

    //  }
}

.modal-header{
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
}
.modal-body{
    justify-content: space-between;
}
.modal-title{
    font-size: 2.5rem;
    position: relative;
    color: white;
}

.close-button{
    border: none;
    background: none;
    font-size: 4rem;
    position: absolute;
    color: $red-ish;
    @include respond(tab-port){
        font-size: 4rem;
     }
     @include respond(phone){
         font-size: 8rem !important;
     }
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
      color: red;
    }
}

.overlay{
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    pointer-events: none;
    transition: all 0.5s;
}

.overlay.active{
    opacity: 1;
    pointer-events: all;
    
}