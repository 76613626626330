// $deep-blue: #242C6B;
$deep-blue:#4d44ac;
$black: #000000;
$white: #ffffff;
$red-ish:  #ff8742;
$red-ish-light: #F6CA5A;
$light-blue: #4085FF;
$dark-blue: #003186;
$light-gray: #e4e4e4;
$purplish: #4A3EAE;
$blue: #2a57e3