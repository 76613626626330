.menu{

    &__icon{
        font-size: 2rem;
        margin-right: 1rem;
        color: white;

    }
    &--container{
        width: 100%;
        background-color: rgb(255, 255, 255);
        
    }
    &--sub{
        display: block;
        padding: 16px 20px;
        background: $light-blue;
        color: white;
        position: relative;
        font-size: 1.5rem;
        transition: all .3s;
        cursor: pointer;
        @include respond(phone){
            display: none;
            font-size: 3rem;
        }

        &:before{
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: $light-blue;
            left: 20px;
            bottom: -7px;
            transform: rotate(45deg);
        }
        &:hover{
            background: rgb(43, 0, 236);
        }

        & img{
            margin-right: 10px;
            width: 3rem;
        }
    }

    &--content{
        // background-color: red;
        height: 0;
        overflow: hidden;
        transition: all .5s;
    }
    &--link{
        display: block;
        font-size: 1.3rem;
        display: block;
        padding: 16px 26px;
        color: black;
        // margin: 4px 0;
        position: relative;
        transition: all .4s cubic-bezier(1,0,0,1) .2s;
        z-index: 0;
        @include respond(phone){
           font-size: 2.5rem;
        }

        &:before{
            // content: "";
            // position: absolute;
            // width: 6px;
            // height: 100%;
            // background: #ffffff;
            // left: 0;
            // top: 0;
            // transition: 0.3s;
            // opacity: 0;        
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background-color: #5eccff;
            transform: scaleY(0);
            transition: transform .2s,
                        width .4s cubic-bezier(1,0,0,1) .2s,
                        background-color .1s;
            z-index: -5;  

        }


        &:hover{
            color: black;
            &:before{
            // opacity: 1;
            transform: scaleY(1);
            width: 100%; 
            }
        }
    }



}

.checkBox{
    display: none;
}

#smenu-1:checked + .menu--content__1{
    height: 380px;
    @include respond(tab-land){
     height: 364px;
    }
    @include respond(big-desktop){
        height: 411px;
    }
}
#smenu-2:checked + .menu--content__2 {
    height: 165px;
}

#smenu-3:checked + .menu--content__3 {
    height: 100px;
}

.activeLink{
    background-color: #020092;
    color: white;
}




.bottomDiv{
    padding: 0 !important;
    margin: 0 !important;
    @include bottom;
    // @include respond(phone){
    //     display: none;
    //     opacity: 0;
    // }
    width: 100%;
    &__2{
        background: white;
        width: 100%;
    }
    &__1,&__2{
        margin: 0;
        padding: 1rem .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @include respond(phone){
            width: 100% !important;
            padding: 1rem 0;
        }
    }
    &--icon{
        width: 2rem;
        @include respond(phone){
            width: 4rem;
        }
    }
    &--text{
        font-size: 1.7rem;
        @include respond(phone){
            font-size: 3rem;
        }
    }
    &--num{
        font-size: 1.3rem;
        @include respond(phone){
            font-size: 2.5rem;
        }
    }
}