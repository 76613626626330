.option{
    background-color: white;
    border-radius: 15px;
    border: 1px #4d44ac solid;
    // border: none;
    box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem;
    cursor: pointer;

    & img{
        width: 30px;
        margin-right: 5px;
    }

    & p {
        font-weight: 400;
    }
}