.login{
    &__bg{
          display: flex;
          padding: 3rem;
          &--forgot{
            background-image: linear-gradient( to right top,
            rgba($deep-blue, 0.501),
            rgba($deep-blue, 0.501) )
           ;      
          }
          @include respond(tab-port){
                flex-direction: column;
                align-items: center;
               }
         }


         &__option{
            padding: 2rem 5rem;
            background-color: white;
            border-radius: 30px;
            width: 40vw;
            position: relative;
            z-index: 1;
            @include respond(tab-port){
                width: 70%;
            }
            @include respond(phone){
                width: 90%;
            }
            margin: 2rem;
            text-align: center;
         }
}