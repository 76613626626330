.jobs{
    &__form{
        background-size: cover;
        background-position: center;
        background: url("../../assets/SVG/bg.svg");
      padding: 4rem 1rem;
    }

    &__indicator{
      background-color: $red-ish;
      padding: 3px 12px;
      color: white;
      margin: 1rem;
      margin-left: 0;
      border-radius:200px;
    }

    &__tag{
      background-color: rgb(243, 243, 243);
      padding: 3px 10px;
      color: $dark-blue;
      margin: 1rem;
      margin-left: 0;
      border-radius: 200px;
      &--tags{
      color: rgb(255, 255, 255);
      background-color: $dark-blue;
      }
    }
}

.board{
  // padding-top: 120px;
  background-size: cover;
  background-position: center;
  background: url("../../assets/SVG/bg.svg");
  display: flex;
  height: 100vh;
  @include respond(tab-port){
    flex-direction: column;
   margin: 1rem;
    justify-content: center;
    align-items: center;
  }
  &__left, &__right{
    overflow: scroll;
    padding: 3rem;
    background-color: white;
  }
  &__left{
    scroll-behavior: smooth;
    width: 30%;
    @include respond(tab-port){
      height: 30%;
      width: 100%;
    }
    @include respond(phone){
      & p, & span{
        font-size: 3.5rem !important;
      }
      & h3{
        font-size: 4rem !important;
      }
    }
    & ul{
      list-style: none;
       border: 1px #000000 solid;
       border-radius: 10px;
       overflow:hidden;
       & li{
        padding: 2rem;
        border-bottom: 1px #C4C4C4 solid;
        font-family: "Poppins", sans-serif;
        position: relative;
        margin-left: 0rem !important;
        & h3{
          font-size: 2rem;
          color: rgb(49, 36, 36);
        }
        & p, span{
          font-size: 1.6rem;
        }
        & span{
          // position: absolute;
          text-align: right;
          right: 2rem;
          bottom: 1rem;
          display: block;
        }
      }
    }

  }
  &__right{
    scroll-padding-top: 1000px;
    width: 70%;
    scroll-behavior: smooth;
    animation: fadin 2s forwards;
    @include respond(tab-port){
      height: 70%;
      width: 100%;
    }
  }
}

.jobactive{
  border-left: 3px $red-ish solid;
}


.jobDetails{
  &__container{
    animation: fadein .5s forwards;
    border: 1px #000000 solid;
    border-radius: 10px;
    overflow:hidden;
    padding: 2rem;
    animation: fadein 2s forwards;
    // @include respond(tab-port){
    //   text-align: center;
    // }
  }

  &__logo{
    width: 10rem;
    border-radius: 10px;
    margin-right : 2rem;
    background-color: black;
  }

  &__buttons{
    display: flex;
    @include respond(tab-port){
      justify-content: left;
    }
     & button:not(:last-child){
       margin-right: 3rem;
     }
  }
}

