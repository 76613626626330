@keyframes expand{
    0%{
        width: 50%;
    }
    100%{
        width: 100%;
    }
}

@keyframes slideRight{
    0%{
        -ms-transform: translate(-100%, -50%) scale(2);
        -webkit-transform: translateX(-100%, -50%) scale(2);
        -moz-transform: translate(-100%, -50%) scale(2);
        transform: translate(-100%, -50%) scale(2);
        opacity: 0;
    }
    100%{
        -ms-transform: translate(-50%, -50%) scale(1);
        -webkit-transform: translateX(-50%, -50%) scale(1);
        -moz-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
@keyframes slideRightwk{
    0%{
        // transform: translate(-100%, -50%);
        opacity: 0;
        scale: 2;
    }
    100%{
        // transform: translate(-50%, -50%);
        opacity: 1;
        scale: 1;
    }
}

@keyframes fadein{
    0%{
       opacity: 0;
    }
    100%{
        opacity: 1;
    }
}


@keyframes fadeout{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


@keyframes expand{
    0%{
        width: 0%;
        opacity: 0;
    }
    100%{
        width: 40%;
        opacity: 1;
    }
}

@keyframes collapse{
    0%{
        width: 40%;
        opacity: 1;
    }
    100%{
        width: 0%;
        opacity: 0;
    }
}