.account{
    &__pic-1,
    &__pic-2{

        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 3rem;
        @include respond(tab-port){
        margin-right: 0;
        }
    }

    &__pic-1{
        width: 20rem;
        height: 20rem;
    }
    &__pic-2{
        width: 15rem;
        height: 15rem; 
    }

    &__companyprofile{
        &__container{
            align-items: center;
        }
        &__name{
            font-size: 3.5rem;
            color: $red-ish;
            font-weight: 500;
            @include respond(phone){
                font-size: 5rem;
            }
        }
        &__link,
        &__link:visited{
            list-style: none;
        }
        // &__location,
        // &__link{
            
        // }
    }

    &__otherDetails{
        &__section{
            border-top: 1px #D9D9D9 solid;
            border-bottom: 1px #D9D9D9 solid;
            padding: 2rem 1rem
        }

        &__tag{
            background-color: rgb(243, 243, 243);
            padding: 3px 10px;
            color: $dark-blue;
            margin: 1rem;
            margin-left: 0;
            border-radius: 200px;
            @include respond(phone){
                font-size: 4rem;
            }
        }
        &__icon{
            font-size: 1.5rem;
            margin-left: 1rem;
            @include respond(phone){
            font-size: 2.5rem;
            }
        }

        &__buttonContainer{
            // justify-content: space-evenly;
        }
    }

}