.home{
    background-color: #F2F5FF;
    &__header{
        &-2, &-3{
            @include respond(phone){
             height: auto
            }
        }
    overflow-x: hidden;
        position: relative;
        width: 100vw;
        height: 100vh;
        @include respond(tab-land){
            heoght: 100vh;
        }
        background: url("../../assets/SVG/bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &__header--2{
            position: relative;
            // overflow-x: hidden;
            height: auto;
            padding: 5rem 2rem 3rem 2rem;
            @include respond(phone){
                padding: 15rem 2rem 3rem 2rem;
            }
            background: url("../../assets/SVG/bg.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        

    &__text-container{
        position: absolute;
        top: 45%;
        left: 10%;
        transform: translateY(-50%);
        // @include respond(tab-land){
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        //     text-align: center;
        //     font-size: 5rem;
        // }
        @include respond(tab-port){
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 5rem;
        }
    }

    &__text, &__text--2{
        color: white;
        font-size: 4rem !important;
        font-weight: 600;
        margin-bottom: 1rem !important;
        @include respond(tab-port){
            font-size: 4.5rem;
            margin-bottom: 2rem;
        }
        // @include respond (tab-port){
        //     font-size: 4.5rem;
        // }

        @include respond(phone){
            font-size: 7rem;
        }

        & span{
            color: $red-ish-light;
            @include respond(tab-land){
                opacity: 1 !important;
            }
        }
    }

    &__text--2{
        text-align: center;
        @include respond(tab-port){
            font-size: 8rem;
            margin-bottom: 2rem;
        }
        // @include respond (tab-port){
        //     font-size: 4.5rem;
        // }

        @include respond(phone){
            font-size: 10rem;
        }
    }
    &__intro{
        background-color: #F2F5FF;
        position: relative;
        padding: 3rem 2rem;
        overflow: hidden;
        // width: 100vw;
        height: auto;

        @include respond(tab-port){
            padding: 3rem 5rem;
        }
        @include respond(phone){
            padding: 3rem 6rem;
        }
        &__content{
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            @include respond(tab-port){
                flex-direction: column;
                justify-content: center;
                align-items: center;   
            }
        }

        &__text{
            & p{
                width: 80%;
                @include respond(tab-land){
                    width : 95% !important;
                }
            }
            width: 40%;
            height: 100%;
            @include respond(tab-port){
                width : 100%;
                text-align: center !important;
            }
        }
    }
}


.left-1{
    position: absolute;
    top: 30%;
    left: -30px;
    width: 15rem;
    &--small{
    width: 10rem;
    left: -5px;
    }
  }
  
  .right-1{
    position: absolute;
    top: 10%;
    right: -5px;
    width: 25rem;
  }
  
  .shape-1{
    width: 15rem;
    bottom: 10%;
    right: 35%;
    position: absolute;
  }


  #laptop-1{
      width: 70rem;
      position: absolute;
      right: -200px;
      top: 20%;
    //   @include respond(tab-land){
    //       width: 50rem;
    //     right: -200px;
    //     top: 25%;
    //   }
      @include respond(tab-port){
        width: 50rem;
        right: -150px;
        top: 30%;
      }
      @include respond(phone){
          display: none;
      }
    //   transform: translateY(-50%);
  }

  #laptop-1-2{
    width: 50rem;
    position: absolute;
    right: -10px;
    top: 30%;
  //   @include respond(tab-land){
  //       width: 50rem;
  //     right: -200px;
  //     top: 25%;
  //   }
    @include respond(tab-port){
      width: 50rem;
      right: -150px;
      top: 30%;
    }
    @include respond(phone){
        display: none;
    }
  //   transform: translateY(-50%);
}

  #home-getstarted{
      opacity: 0;
      animation: fadein 1s 1s forwards;  
      -webkit-animation: fadein 1s 1s forwards;
  }


.shape-2{
      position: absolute;
      width: 20rem;
      left: -30px;
    z-index: 0;

  }

  .shape-3{
    position: absolute;
    width: 23rem;
    right: -100px;
    bottom: 0;
    z-index: 0;
    &--small{
        width: 20rem;
      }
}


#workers{
    width: 45%;
    @include respond(tab-port){
        width : 100%;
    }
}


.our-specializations-container{
    width: 50vw;
    left: 5%;
    top: 50%;
    color: white;
    @include respond(tab-port){
        left: 50% !important;
        width: 80vw;
    }
    @include respond(phone){
        width: 90vw;
        top: unset !important;
        left: unset !important;
        position: relative;
        font-size: 1rem !important;
        transform: translate(0, 10%);
    }
}