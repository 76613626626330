.singleArticle{
    &__header{
        width: 100vw;
        height: 100vh;
        padding-top: 8rem;
        &__banner{
            height: 50%;
            background-size: cover;
            background-position: center;
        }

        &__bottom{
            padding: 0 !important;
            height: 50%;
            @include respond(phone){
               text-align: center;
            }
            &--1,
            &--2{
                position: relative;
                width: 50%;
                height: 100%;
                @include respond(phone){
                    width: 100%;
                }
            }
            
            &--1{
                background-color: white;
                padding-left: 2rem;
                & h2{
                    font-size: 5rem;
                    font-weight: bold;
                    color: $deep-blue;
                    @include respond(tab-port){
                        margin: 2rem;
                    font-size: 3rem;
                    }
                    @include respond(phone){
                        padding: 2rem;
                        font-size: 5rem;
                    }
                }

                & p{
                    color: $red-ish;
                    font-size: 2rem;
                    font-weight: lighter;
                    position: absolute;
                    bottom: 0;
                    left: 2rem;
                }
            }

            &--2{
                background-color: $deep-blue;
                // display: ;
                div div h3{
                    font-size: 3.5rem;
                    font-weight: bold;
                    @include respond(phone){
                        text-align: center !important;
                     }
                }

                div div h4{
                    font-size: 2rem;
                    font-weight: lighter;
                    @include respond(phone){
                        text-align: center !important;
                     }
                }

                &__socials{
                    position: absolute;
                    bottom: 0;
                    right: 2rem;
                    @include respond(phone){
                        display: flex;
                        justify-content: flex-end;                        
                     }

                    &__icon{
                        width: 5rem;
                        margin-right: 1rem;
                        @include respond(phone){
                        width: 8rem;
                        }
                    }

                    @include respond(phone){
                        position: relative;
                        
                     }
                }

                &__picture{
                    border-radius: 50%;
                    width: 15rem;
                    @include respond (phone){
                    width: 25rem;
                    padding: 3rem !important;
                    }
                }
            }
        }
    }


    &__content{
        background-color: white;
        padding: 2rem 20rem;
        @include respond(tab-port){
            padding: 2rem 4rem;
         }
         @include respond(phone){
            padding: 2rem 8rem;
         }

        &__image{
            width: 100%;
            border-radius: 20px;
        }

        &__paragraph{
            font-size: 1.6rem;
            @include respond(tab-port){
                font-size: 3rem;
             }
        }

        &__quote{
            background-color: $deep-blue;
            border-radius: 20px;
        }
        &__quoteText{
            color: $red-ish;
            font-size: 5rem;

            & span{
                color: white;
            font-size: 2rem;
            @include respond(tab-port){
                font-size: 3rem;
             }
            }
        }
        &__quoteAuthor{
            color: $red-ish;
            font-size: 2rem;

        }
        & h1,
        & h2,
        & h3,
        & h4{
            font-weight: bold;
            margin-bottom: 2rem;
        }


        & h1{
            font-size: 4rem;
        }
        & h2{
            font-size: 3rem;
        }
        & h3{
            font-size: 2rem;
        }
        & h4{
            font-size: 1rem;
        }
    }


}