.notification{
    background-color: white;
    padding: 2rem 2rem;
    border-radius: 20px;
    box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    margin-bottom: 2rem;
    position: relative;
    &__info{
        margin-top: 1rem;
    }
    &__dismiss{
        position: absolute;
        bottom: 10px;
        right: 30px;
        background-color: $red-ish;
    }
    &__span{
        color: rgb(0, 0, 0);
        background-color: $red-ish-light;
        padding: 1px 5px;
        border-radius: 100px;

    }
    &__header{
        justify-content: space-between;
        
        & h3{
           font-size: 2rem ;
           font-weight: 500;
           color: $deep-blue;
           text-transform: capitalize;
            @include respond(tab-port){
                font-size: 3rem;
            }
            @include respond(phone){
                font-size: 4rem;
            }
        }
    }

    &__requestbody{
        display: flex;
        justify-content: space-between;
        @include respond(tab-port){
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }
        &__buttons{
            @include respond(tab-port){
               margin-top: 2rem !important;
            } 
        }
    }
}