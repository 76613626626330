
.invoice-div-2{

    & div{
        &:first-child{
            margin-right: 10rem;
            @include respond(tab-port){
                margin-right: 0;
                margin-bottom: 5rem;
            }
        }
    }
}

.invoice-div-1, .invoice-div-2{
    @include respond(tab-port){
        align-items: flex-start !important;
    }
}

.invoice-row{
    display: flex;
    justify-content: space-between;
    &:not(:last-child){
        margin-bottom: 5px;
    }
}

.invoice-table{
    width: 48%;
    @include respond(tab-port){
        width: 100%;
    }
    &:first-child{
        margin-right: 10px;
        @include respond(tab-port){
            margin-right: 0px !important;
        }
    }
    &__header{
        background-color: rgb(36, 36, 36);
        color: white;
        font-size: 2rem;
        padding: 10px;
    }
    &__details{
        background-color: rgb(238, 238, 238);
    }
}