.bugs{
    &--header{
        background-color: rgb(66, 66, 66);
        justify-content: space-around;
        font-weight: 400;
        font-size: 1.6rem;
        color: white;
        @include respond(phone){
            display: none !important;
        }
    }

    &--data{
        justify-content: space-evenly;
        padding: 1rem 0.5rem;
        border-top: white .5px solid;
        border-left: white .5px solid;
        border-right: white .5px solid;
        

        @include respond(phone){
            margin-bottom: 4rem;
            border: black 2px solid;
            border-radius: 20px;
        }
    }

    &--row{
        width: 100%;
        display: flex;
        margin-left: 0rem;
        @include respond(phone){
            justify-content: flex-start !important;
            align-items: center;
            margin-bottom: 5rem;

        }
        
        
        // justify-content: space-around; //in media query

        &--title{
            // display: none;
            // width: 50%;
            background-color: rgb(48, 48, 48);
            font-weight: 400;
            font-size: 3rem;
            display: none;
           color: white;
            @include respond(phone){
                display: block;
                font-size: 3rem;
                margin-bottom: 2rem;
                margin-right: 2rem;
                min-width: 40%;
            }

        }

        &--value{
            // width: 50%;
            font-size: 1.4rem;
            // font-weight: lighter;
            // white-space: initial;
            overflow: hidden;
            text-overflow: ellipsis;

            @include respond(phone){
                font-size: 3.5rem;
            }
        }
    }

    &--icon{
        cursor: pointer;
        width: 2rem;
        @include respond(phone){
        width: 6rem;
        }
    }
    &--more{
        width: 3.5rem;
        cursor: pointer;
        @include respond(phone){
            width: 6rem;
            }
    }

    &--eye{
        width: 3rem;
        cursor: pointer;
        @include respond(phone){
            width: 6rem;
            }
    }
}