.result{
    background-color: white;
    box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    display: flex;
    padding: 10px;
    border-radius: 10px;
    width: 500px;
    margin-bottom: 20px;
    &__inner{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header{
        font-size: 25px;
        font-weight: bold;
    }

    &__img{
        width: 100px;
        height: 100px;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        margin-right: 20px;
    }
}

.selected{
    &__img{
        width: 200px;
        height: 200px;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        margin-right: 20px;
    }
}