.employerReg{
    &__buttonContainer{
        width: 50%;
        @include respond(tab-port){
            width: 100%;
        }
        @include respond(phone){
            width: 80%;
        }
    }
}