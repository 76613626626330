.article{
    &__item{
        background-color: white;
        transition: all .3s ease-in;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        height: 100%;


        &--authorPic{
            height: 8rem !important;
            width: 8rem !important;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            margin-right: 3rem;
            margin-left: 2rem;
        }

        &__buttons{
            margin-top: 2rem;
            display: flex;
            width: 100%;
            justify-content: space-evenly;
        }


        &:hover{
            transform: scale(1.05);
            background-color: $red-ish;
                    box-shadow: 1px 4px 11px -1px rgba(0,0,0,0.29);
        -webkit-box-shadow: 1px 4px 11px -1px rgba(0,0,0,0.29);
        -moz-box-shadow: 1px 4px 11px -1px rgba(0,0,0,0.29);

            & .article__item__bottom{
            //   height: 100%;
            //   top: 0  
            background-color: white;

            & div h1{
                color: $red-ish
            }
            }

            .article__item__bottom--author{
                opacity: 1;
                height: auto;
                padding: 5px;
            }

        }
        &__bottom{
            transition: all .3s;
            background-color: $deep-blue;
            width: 100%;
            position: absolute;
            bottom: 0;
            padding: 1rem;
            

            &--title{
                background-color: white;
                padding: 10px;
                & h1{
                    color: $deep-blue;
                    text-align: left !important;

                    @include respond(phone){
                        font-size: 4rem !important;
                    }
                }
                
            }

            &--author{
                background-color: $deep-blue;
                // padding: 20px;
                display: flex;
                opacity: 0;
                height: 0;
                padding: 0;
                transition: all .5s;
                & h3:nth-child(1){
                    font-size: 2.5rem;
                    text-transform: capitalize;
                }
                & h3:nth-child(2){
                    font-weight: lighter !important;
                }


                @include respond(phone){
                justify-content: space-evenly;
                    flex-direction: row !important;
                    padding-bottom: 0;
                    & h3:first-child{
                        font-size: 5rem;
                    }
                    & h3:nth-child(2){
                        font-size: 3rem;
                    }
                    & div img{
                        width: 12rem !important;
                        height: 12rem !important
                    }
                }
            }



            & div h1{
            transition: all .3s;
                color: white;
                font-size: 2.5rem;
                text-transform: capitalize;
                text-align: center;
            }
        }
    }
    &__plus{
        width: 7rem;
    }

    &__button{
        padding: 0.3rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    &__social{
        &__icon{
            width: 3rem;
            margin-right: 1rem;
        }
        &__button{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 2rem;
            background-color: $light-blue;
            border-radius: 20px;
            border: none;
            color: white;
            font-size: 1.7rem;
            @include respond(tab-port){
                font-size: 3rem;
             }
             @include respond(phone){
                font-size: 5rem;
             }
            font-family: "Ubuntu", sans-serif;
            font-weight: bold;
            cursor:pointer;
            transition: all .3s;

            @include respond(phone){
                margin-bottom: 3rem;
            }
            
        
            &:hover{
                background-color: white;
                color: $red-ish;
                box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.1);
            }
        
            &:active{
                background-color: $red-ish;
                color: black;
                box-shadow: none;
            }

        }
    }
}



.articlesList__div{
    background-color: white;
}


.articles__header{
    width: 50rem;
}


