.home2{
    width: 100%;
    height: 100vh;
    display: flex;
    @include respond(phone){
    display: unset;
    }
}


#navigation{
    width: 15%;
    background-color: #fff;
    position: relative;
    -webkit-box-shadow: 0px 0px 17px -6px rgba(0,0,0,0.11); 
    box-shadow: 0px 0px 17px -6px rgba(0,0,0,0.11);
    @include respond(phone){
        width: 0%;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 7000;
        overflow-y: scroll;
    }



    height: 100vh;
    overflow-y: scroll;
}
#dashboard{
    width: 85%;
    overflow-y: scroll;
    position: relative;
    scroll-behavior: smooth;
    
    /*Scrollbar customization*/
              /* width */
              ::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey; 
                background-color: #fff;
              }
               
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #323fcd; 
                height: 200px;
              }

              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: rgb(255, 187, 0); 
              }

              
    @include respond(phone){
        width: 100%;
        padding: 5rem;
        min-height: 100vh;
    }
}

.dash{
      &__logo{
        width: 10rem;
        margin: 5px 0rem;
        @include respond(phone){
          width: 30rem;
        }
        &-container{
          @include respond(phone){
            display: none;
          }
        }

    }


    &--icon{
      display: none;
      width: 10rem;
      cursor: pointer;
    }
}

#dash{
  &--open{
    z-index: 5000;
    animation: fadein 1s;
    @include respond(phone){
        display: block; 
        position: fixed;
        top: 5rem;
        left: 5rem;
    }
  }

  &--close{
    position: relative;
    // top: 5rem;
    // left: 5rem;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-bottom: 3rem;
    width: 10rem;
    z-index: 9000;
    animation: fadein 1s;
    @include respond(phone){
        display: none;
    }
  }

}

/*Scrollbar customization*/
              /* width */
              ::-webkit-scrollbar {
                width: 8px;
                @include respond(phone){
                  width: 2px;
                }
              }

              /* Track */
              ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey; 
                background-color: #fff;
              }
               
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #324ecd; 
                height: 100px;
              }

              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: rgb(255, 187, 0); 
              }
