@mixin center{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@mixin bottom{
    position: absolute;
    bottom: 0;
}

@mixin respond($breakpoint){
    
    @if $breakpoint == phone{ 
         @media (max-width: 37.5em) {   //600px
             @content;
        }
    }  

    @if $breakpoint == tab-port { 
        @media (max-width: 56.25em){     //900px
            @content;
        } 
    }  

    @if $breakpoint == tab-land {
        @media (max-width: 75em){    //1200px
            @content; 
        }
    }
    

 
  @if $breakpoint == big-desktop{
       @media (min-width: 112.5em){        //1800px
           @content;
       }
   }
}