.expertise__hero {
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0;

  @include respond(tab-port) {
    text-align: center;
  }
  &__img {
    width: 550px;
    @include respond(tab-port){
        width: 300px;
    }

    &--big{
      width: 600px;
      @include respond(tab-port){
          width: 300px;
      } 
    }
  }

  &__bg {
    &--1{
        border-top-left-radius: 101px !important;
        border-bottom-left-radius: 220px !important;
        padding-left: 10rem;
        padding-bottom: 0PX !important;
    }
    &--2{
        border-top-right-radius: 40px !important;
        border-bottom-right-radius: 90px !important;
        padding-right: 8rem;
        padding-left: 1rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    background: rgb(66, 90, 168);
    background: linear-gradient(
      139deg,
      rgba(66, 90, 168, 1) 0%,
      rgba(88, 64, 152, 1) 100%
    );
    
    padding-top: 4rem;
    box-shadow: 1px 4px 14px 7px rgba(0, 0, 0, 0.26);
    -webkit-box-shadow: 1px 4px 14px 7px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 1px 4px 14px 7px rgba(0, 0, 0, 0.26);

    @include respond(tab-port) {
      border-radius: 20px !important;
      padding: 3rem;
    }
  }

  &__text {
    width: 50%;
    padding: 3rem;
    @include respond(tab-port) {
      width: 90%;
      margin-bottom: 2rem;
      text-align: center !important;
    }

    & h2 {
      font-weight: bold;
      font-size: 3rem !important;
      @include respond(tab-port) {
        font-size: 5rem !important;
      }
    }

    & .content {
      padding-right: 2rem;
    }
  }
}

.expertise{
    &__pill{
        background-color: #FFE2A3;
        margin: 1rem;
        border-radius: 10px;
        text-align: center;
        padding: 10px 15px;
        font-weight: 500;
        &--purple{
        background-color: rgba(66, 90, 168, 1) !important;
        color: white;
        }
       }

       &__feature{
        background-color: white;
        border-radius: 35px;
        text-align: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 28px 7px rgba(224,220,255,0.77);
        -webkit-box-shadow: 0px 4px 28px 7px rgba(224,220,255,0.77);
        -moz-box-shadow: 0px 4px 28px 7px rgba(224,220,255,0.77);
        padding: 5rem 1rem;
        margin: 1rem;
        @include respond(tab-port){
        padding: 5rem 3rem;
        }
        @include respond(phone){
        padding: 5rem;
        border-radius: 30px;
        margin: 2rem;
        }
         &__img{
            width: 90px !important;
            height: 90px !important;
         }

         & p{
        font-weight: 400;
        color: #52479D;
        font-size: 1.6rem;
        @include respond(phone){
        font-size: 3rem;
        }
         }
       }


       &__container{
        width: 50%;
        @include respond(tab-port){
        width: 100%;
        }

        
        @include respond(phone){
            padding: 1rem 5rem !important
        }
       }
}

.orange {
  background-color: #eec05d;
  width: 185px;
  height: 17px;
  border-radius: 10px;
  @include respond(tab-port) {
    display: none;
  }
}

//////////////////////////////new
.expertiseHero{
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;


}

.expertiseIntro{
  background-color: rgba(42,87,227, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 20px 10px 30px 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  @include respond(tab-port){
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 20px;
  }
}


.expertiseDetails{
  padding: 0;
  width: 100%;
  &-1,&-2{
    width: 50%;
    padding: 50px 30px;
    @include respond(tab-port){
      width: 100%;
    padding: 50px 20px;
    }
  }
  &-1{
    
  }
  &-2{
    background-color: $blue;
    & p{
      border-bottom: white 1px solid;
      margin-bottom: 40px;
    }
  }
}


.expertiseList-container{
  width: 100%;
  overflow: auto;
  background-position: center;
  background-size: cover;
}

.expertiseList-overlay{
  width: 100%;
  height: 100%;
  background-color: rgba(42,27,227,.48);
}


.Collapsible{
  background: white;
    width: 50%;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    font-size: 16px;
  @include respond(tab-port){
    width: 90%;
  }

}

.Collapsible__trigger{
  width: 100%;
  display: block;
}

.expertise-list{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
  @include respond(tab-port){
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  & li{
      margin-bottom: 5px;
  }
}