.input-text,.react-select__control{
    font-size: 1.7rem;
    font-family: "Poppins", sans-serif;
    font-weight: 300;

    @include respond(tab-port){
        font-size: 3rem !important;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
    }

        @include respond(phone){
            font-size: 4rem !important;
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            padding: 3rem 3rem;
        }
}

.valid{
    border: green 3px solid !important;
    background-color: green;
    color: white;
}
.invalid{
    border: red 3px solid !important;
    background-color: rgb(128, 0, 0);
    color: white;
}

.input{
    &-textbox, &-textarea{
        &-special{
            width: 100%;
            margin-left: 0;
            margin-top: 0;
        }
        margin: 2rem 2rem 2rem 2rem;
        &-home{
        margin: 2rem 2rem 2rem 0 !important;
        }
    //    width: 100%;
        border-radius: 20px;
        border: 1px #4d44ac solid;
        // border: none;
        box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
        @include respond(tab-port){
            padding: 5px !important;
        }
        padding: 10px 10px;
        transition: all .2s;
        font-size: 1.5rem;
        font-family: "Poppins", sans-serif;
        width: 80%;
        @include respond(tab-port){
            font-size: 3rem !important;
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            width: 100%;
        }
    
        @include respond(phone){
            font-size: 4rem !important;
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            padding: 3rem 3rem;
            width: 100%;
        }
        &:placeholder-shown + label{
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: all .2s;
            font-family: "Poppins", sans-serif;
        }
    }
    &-password{
      
             border-radius: 30px;
             border: none;
             box-shadow: 2px 0px 10px 0px rgba(5, 5, 5, 0.075);
             padding: 1.5rem 2rem;
             transition: all .2s;
             font-size: 1.5rem;
             font-family: "Poppins", sans-serif;
             width: 80%;
             
             @include respond(tab-land){  
                 font-size: 3rem;
               }
             @include respond(phone){  
                 font-size: 3rem;
               }
             &:placeholder-shown + label{
                 opacity: 0;
                 visibility: hidden;
                 transform: translateY(-10px);
                 transition: all .2s;
                 font-family: "Poppins", sans-serif;
             }


        
    }
    &-checkbox{
        border: 2px solid black;
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 7px !important;
        @media  screen and (max-width: 900px) {
            .input-checkbox{
                
            }
        }
    }
    &-number{
        width: 80%;
        border: none;
        border-radius: 30px;
        padding: 1.5rem 2rem;
        font-family: "Poppins", sans-serif;
        box-shadow: 2px 0px 10px 0px rgba(5, 5, 5, 0.075);
        @include respond(tab-port){
            font-size: 3rem !important;
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            padding: 2rem 3rem;
        }
    
        @include respond(phone){
            font-size: 4rem !important;
            font-family: "Poppins", sans-serif;
            font-weight: 300;
            padding: 3rem 3rem;
        }

        &:placeholder-shown + label{
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: all .2s;
            font-family: "Poppins", sans-serif;
        }
    }
    &-select{
        padding: 1.5rem 2rem;
        border-radius: 30px;
        border: none;
        width: 80%;
        font-family: "Poppins", sans-serif;
    }
    &-textarea{
        // width: 50%;
        height: 10rem;
        font-family: "Poppins", sans-serif;
        border-radius: 20px;
        padding: 1rem;
        border: none;
    }
    &--label{
        font-size: 1.5rem;
        // color: white;
        @include respond(tab-port){
            font-size: 3rem; 
                                            //1rem = 8px , 8/16  50%
            } 
        
    }
    &-time{
        border: black 1px solid;
        padding: 5px;
    }
}

.breaktime{
    border: black 1px solid;
    padding: 5px;
}

.radio{
    // visibility: hidden;
    opacity: 0;
    display: none;
}

.radio-field{
    display: flex;
    justify-content: center;
}

.radio-field input[type="radio"]{
    opacity: 0;
    width: 0;

}

.radio:checked + label{
    color: white;
    /* background-position: 100%; */
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.568);
    background-color: $deep-blue;
    transform: scale(0.9);
}

.radioLabel-active{
    color: white;
    /* background-position: 100%; */
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.568);
    background-color: $deep-blue;
    transform: scale(0.9);
}

.radio-label:focus {
    border: 2px solid rgb(255, 255, 255);
}

.radio-label{
    font-size: 15px;
    display: inline-block;
    text-transform: uppercase;
    color: #000000;
    /* background-image: linear-gradient(120deg,#15003500 50%, #4204a0 80%, #150035 100%); */
    -webkit-box-shadow: 9px 7px 26px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 9px 7px 26px -11px rgba(0,0,0,0.75);
    box-shadow: 9px 7px 26px -11px rgba(0,0,0,0.75);

    padding: 12px 25px;
    border-radius: 300px;
    transition: all 0.5s;
    /* background-size: 250%; */
    backface-visibility: hidden;

}

.radio-label:hover{
    -webkit-box-shadow: 16px 21px 41px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 16px 21px 41px -11px rgba(0,0,0,0.75);
    box-shadow: 16px 21px 41px -11px rgba(0,0,0,0.75);
    transform: scale(1.1);
    color: $deep-blue;
}

.react-select__control{
    width: 100%;
}

.select__container{
    width: 80%;
    display: flex;
    align-items: center;
}

#ais-SearchBox-input{
//    width: 100%;
    border-radius: 20px;
    border: 1px #4d44ac solid;
    margin-right: 20px;
    margin-left: 0 !important;
    // border: none;
    box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    @include respond(tab-port){
        padding: 5px !important;
    }
    padding: 10px 10px;
    transition: all .2s;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    width: 500px;
    @include respond(tab-port){
        font-size: 3rem !important;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        width: 100%;
    }

    @include respond(phone){
        font-size: 4rem !important;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        padding: 3rem 3rem;
        width: 100%;
    }
    &:placeholder-shown + label{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        transition: all .2s;
        font-family: "Poppins", sans-serif;
    }
}

.ais-SearchBox-submitIcon{
    width: 30px !important;
    height: 30px !important;
}

.ais-SearchBox-form{
    display: flex !important;
    align-items: center !important;
    margin: 20px 0;

}


