// ReactTags__tags
// ReactTags__tagInput
// ReactTags__tagInputField
// ReactTags__selected
// ReactTags__selected ReactTags__tag
// ReactTags__selected ReactTags__remove
// ReactTags__suggestions
// ReactTags__activeSuggestion

.ReactTags__tagInputField{
    border-radius: 10px;
    border: 1.8px black solid;
    box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 21px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    transition: all 0.2s;
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    width: 80%;
}



.ReactTags__selected {
    margin-bottom: 1rem;
    .ReactTags__tag{
        background-color: $red-ish;
        padding: 3px 12px;
        color: white;
        margin: 1rem;
        margin-left: 0;
        border-radius:200px;
        font-size: 1.6rem;
        @include respond(phone){
            font-size: 4rem;
        }
    }

    .ReactTags__remove{
        background-color: transparent;
        border: none;
        color: white;
        font-size: 2rem;
        margin: 5px;
        cursor: pointer;

    }
}