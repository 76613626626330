.signup{
    &__bg{
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-evenly;
      padding: 3rem;
      width: 90vw;
      @include respond(tab-port){
          flex-direction: column;
          align-items: center;
      }

    }

    &__option{
        padding: 2rem 5rem;
        background-color: white;
        border-radius: 30px;
        width: 30%;
        @include respond(tab-port){
            width: 70%;
        }
        @include respond(phone){
            width: 90%;
        }
        margin: 2rem;
        text-align: center;
    }

    &__formbg{
        padding: 3rem;
        background-color: white;
        border-radius: 20px;
        width: 60%;

        & .row{
            justify-content: space-between;
            align-items: center !important;
        }
        @include respond(tab-port){
            width: 100%;
        }

        @include respond(phone){
        margin: 0;
        padding: 3rem 0;
        }
        margin: 2rem;
        text-align: center;
    }
}
