.video{
    border-radius: 20px;
    border: none;
    box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
}

.mockup{
    @include respond(tab-port){
        width: 90vw !important;
    }
    width: 80rem;
    height: 40rem;
    background-size: cover;
    background-position: center;
}