.loading{
    width: 20rem;
    &__container{
        width: 100%;
        height: 100%;
        // position: absolute;
        top:0;
        left:0;
        background-color: white;
        z-index: 10000;
    }
}

.loadingSmall{
    width: 10rem;
    &__container{
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        background-color: white;
        position: absolute;
        z-index: 2;
    }
}

.loadingLine{
    width: 30rem;
    &__container{
        width: 190px;
        height: 35px;
        top:0;
        left:0;
        background-color: white;
    }
}

