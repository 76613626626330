.query{
    &__bar{
        background-color: white;
        padding: 1 rem;
        display: flex;
        z-index: 20;
        position: relative;
        @include respond(tab-port){
        flex-wrap: wrap;
        }
        &__inp{
            width: 100% !important;
        }


    }

    &__pillContainer{
        padding: 1rem;
    }
    &__pill{
        background-color: $light-blue;
        display: inline-block;
        margin: 1rem;
        padding: 5px 10px;
        padding-right: 40px;
        color: white;
        border-radius: 200px;
        position: relative;

        &__close{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            padding: 2px;
            border-radius: 50%;
            display: inline-block;
        cursor: pointer;

        }
    }
}