.footer{
    background: rgb(10,89,229);
             background: linear-gradient(90deg, rgba(10,89,229,1) 0%, rgba(102,45,145,1) 100%);
    padding: 2rem 3rem 1rem 3rem;
    // position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    &-row{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap !important;
        // max-width: 1200px !important;

        @include respond(tab-port){
            flex-direction: column;
            align-items: center;
        }
    }
    &__logo{
        width: 20rem;
        @include respond(phone){
            width: 200px;
            margin: 20px;
        }
    }
    &__social{
        color: white;
        font-size: 2rem;
        @include respond(tab-port){
            font-size: 3rem
        }
        @include respond(phone){
            font-size: 5rem;
            &:not(:last-child){
                margin-right: 2rem;
            }
        }
    }
    &__list{
        color: white;
        font-size: 1.3rem;
        @include respond(tab-port){
            text-align: center
        }
        & h3{
        font-size: 2rem !important;
        margin-bottom: 2rem;
        color: $red-ish-light;
        @include respond(tab-port){
        font-size: 5rem !important;
        margin-top: 50px;
        }
        @include respond(phone){
            font-size: 6rem !important;
            margin-top: 50px;
            }
        }

        & ul li{
            list-style: none;
            margin-bottom: 10px;
            @include respond(tab-port){
                font-size: 3rem;
                margin-bottom: 40px;
                }
                @include respond(phone){
                    font-size: 4rem;
                margin-bottom: 35px;
                    }
            
            & a{
                color: white;
                text-decoration: none;
            }

        }
    }
    &__list-item{
        margin-left: 0rem !important;
    }
}
