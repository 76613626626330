
.containerr {
	// position: absolute;

}

table {
	width: 1100px;
	border-collapse: collapse;
	overflow-x: scroll;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	background-color: white !important;
}
.smalltable{
    width: auto;
    table-layout: fixed;
}

.table-sub{
    width: 33%;
}

th,
td {
	padding: 15px;
	background-color: rgba(255,255,255,0.2);
}

th {
	text-align: left;
	color: #fff;
    border: 1px solid white;
}

thead {
	th {
		background-color: #55608f;
	}
}

tbody {
	tr {
		&:hover {
			background-color: rgba(255,255,255,0.3);
		}
	}
	td {
		position: relative;
    border: 1px solid $deep-blue;
		// &:hover {
		// 	&:before {
		// 		content: "";
		// 		position: absolute;
		// 		left: 0;
		// 		right: 0;
		// 		top: -9999px;
		// 		bottom: -9999px;
		// 		background-color: rgba(255,255,255,0.2);
		// 		z-index: -1;
		// 	}
		// }
	}
}

.break_inputs{
	border-right: $deep-blue 5px solid;
}

.break_header{
	border-right: $deep-blue 5px solid;
}


#imagetomake{
background-color: white;
}