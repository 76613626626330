.pricing{
    &__header{
        position: relative;
        background-size: cover;
        background-position: center;
        // background-image: url(../../assets/pricingbg.jpg);
      padding: 12rem 1rem;
      text-align: center;
    }

    &__overlay{
        position: absolute;
        background-image: linear-gradient( to right top,
        rgba($red-ish, 0.7),
        rgba($red-ish, 0.7) );
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 4rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(2px) contrast(200%) saturate(150%);
    }

    &__item{
        border-radius: 20px;
        border: none;
        box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
        -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
        -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
        margin: 2rem;
        &__header{
            font-family: "Poppins", sans-serif;
            text-align: center;
            font-weight: 700;
            color: white;
            border-radius: 10px;
            padding: 10px;
            text-transform: capitalize;
        }
        &__rest{
            padding: 2rem 3rem;
            & h3{
               font-family: "Poppins", sans-serif;
            }

            & ul{
                list-style: none;
                font-weight: 400;

                & li{
                    margin-bottom: 10px;
                }
            }
        }
    }
}