.feature{
    &-container{
        justify-content: space-evenly
    }
    margin: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    padding: 3rem 5rem;
    border-radius: .5rem;
    transition: all .4s;
    cursor: pointer;
    box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    width: 33%;
    -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
    @include respond(tab-port){
        width: 80%;
    }

    &__icon{
        font-size: 5rem;
        background-image: linear-gradient( to right,
        rgba(white, .5) 50%,
        rgba(gray, 1)  );
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        backface-visibility: hidden;
        transition: all .4s;

    }



    &__header{
        text-transform: uppercase;
        color: $red-ish;
        transition: all .4s;
        margin: 1.5rem 0;
        font-size: 2rem;
    }

    &__text{
        font-size: 1.6rem;
    }

    &:hover{
        transform: scale(1.1) translateY(-15px);
        box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.5);
    
    }

    &:hover &__icon{
        color: black;
    }

}