.comment{
    &--container{
        position: relative;
        width: 100%;
        background-color: $dark-blue;
        color: white;
        padding: 1rem 2rem 3rem 2rem;
        border-radius: 20px;
        border: none;
        -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
        -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
        box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
        margin-bottom: 11rem;
        &:hover .comment--delete{
            opacity: 1;
            transform: scale(1.1);
        }

        &--input{
            background-color: white;
            position: relative;
            width: 80%;
            background-color: white;
            padding: 1rem ;
            border-radius: 20px;
            border: none;
            -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
            -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
            box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.1);
            margin-bottom: 11rem;
            & textarea{
               width: 90%;
            //    height: 8rem;
               margin: 1rem 3rem;
               border: none;
               font-family: "Evolventa", sans-serif;
            }
        }
    }
    &--button{
        position: absolute;
        bottom: 3rem;
        right: 2rem;
        -webkit-box-shadow: 6px 4px 10px 1px rgba(0,0,0,0.39);
        -moz-box-shadow: 6px 4px 17px 1px rgba(0,0,0,0.39);
        box-shadow: 6px 4px 17px 1px rgba(0,0,0,0.39);
    }
    &--pic{
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: -2rem;
        left: -2rem;
        -webkit-box-shadow: 6px 4px 10px 1px rgba(0,0,0,0.39);
        -moz-box-shadow: 6px 4px 17px 1px rgba(0,0,0,0.39);
        box-shadow: 6px 4px 17px 1px rgba(0,0,0,0.39);
    }
    &--title,
    &--text{
        margin-left: 3rem;
        white-space: pre-wrap;
        & p{
            width: 95%;
        }
    }

    &--title{
        font-size: 2rem;
        // font-weight: 100;
        margin-bottom: 2rem;
        & span{
            color: white;
            text-transform: capitalize;
        }
    }
    &--text{
        // height: 20rem;
        // overflow-y: scroll;
        font-size: 1.6rem;
    }
    &__bottom{
        position: fixed !important;
        bottom: 0;
        padding: 1rem;
        margin: 0 0 1rem 0 !important;
        width: 80% !important;
        // border-radius: 5px;
    }

    &--delete{
        opacity: 0;
        transition: all .3s;
        width: 2rem;
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    &--down{
        position: absolute;
        right: 0;
        top: -5rem;
        background-color: white;
        border: none;
        padding: 1rem;
        border-radius: 50%;
        transition: all .3s;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
        &:hover{
            background-color: $red-ish;
            transform: scale(1.1);
        }
        // margin-bottom: 2rem;
    }


}

// .dvisible{
//     opacity: 1;
//     transform: scale(1);
// }

.dinvisible{
    opacity: 0 !important;
    transform: scale(0) !important;
}

#new-comment{
    font-size: 1.7rem !important;
    font-family: "Poppins", sans-serif;
    @include respond(tab-port){
        font-size: 2rem !important;
     }
    @include respond(phone){
        font-size: 3rem !important;  
      }
}


.comment-home{
    position: relative;
    padding: 2rem;
}