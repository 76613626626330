.jobThumb{
    display: flex;
    width: 70vw;
    padding: 1rem 2rem;
    justify-content: space-between;
    border-bottom: 1px rgb(185, 185, 185) solid;
    margin-top: 2rem;
    @include respond(tab-port){
        width: 100vw;
    }

    &__buttoncontainer{
        padding-top: 5px;
    }
}