.demo{
    text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.48);
    margin: 5rem;
    background-color: $deep-blue;
    border-right: 5px solid $deep-blue; 
    background-size: cover;
    background-position: center;
    justify-content: space-around;
    align-items: center;
    padding: 5px 1rem !important;
    &-textbox{
      margin: 2rem 2rem 1rem 0;
      width: 90%;

    }
    &--1,&--2{
      margin: 0 2rem;
      @include respond(tab-port){
        margin-top: 2rem;
      }
    }
    &--2{
      background-color: white;
      color: black;
      padding: 3rem;
      width: 50%;
      @include respond(tab-port){
        width: 90%;
      }
    }
    &--1{
      margin-left: 2rem;
      @include respond(tab-port){
        margin-left: 0;
      }
    }
    &__header{
      color: white;
      font-size: 4rem;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
    }

    &__list{
      list-style: none;
      color: white;
      

      & li{
        margin-bottom: 10px;
      }
    }

    &__icon{
      color: white;
      font-size: 10rem;
    }
}