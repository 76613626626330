.button{
    &--purple{
        background-color: #52479D !important;
        border-radius: 15px !important;
        color: white !important;
        padding: 10px 25px;
    }
    &--green{
        background-color: rgb(1, 165, 1);
    }
    &--getstarted{
        border: 2px solid white;
        background-color: transparent;
        & a{
            color: white;
        }
        &:hover{
            & a{
                color: $light-blue;
            }  
        }
        &:active{
            & a{
                color: white;
            }   
        }
        
        &.visible{
            border: 2px solid $light-blue;
            color: $light-blue; 
            & a{
                color: $light-blue; 
            }
        }
    }
    &--red{
    background-color: red;
    &:hover{
        color: red !important;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.1);
    }

    @include respond(tab-port){
        margin-right: 0 !important;
        margin-bottom: 10px;
    }

    &:active{
        background-color: red !important;
        color: white !important;
        box-shadow: none;
        transform: scale(0.8);
    }

    }
    &--blue{
    background-color: blue;
    &:hover{
        color: blue !important;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.1);
    }

    &:active{
        background-color: blue !important;
        color: white !important;
        box-shadow: none;
        transform: scale(0.8);
    }
    }


    &--flip{
    background-color: $red-ish;
    }
    &--red-ish{
    background-color: $red-ish;
    box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 27px 2px rgba(0,0,0,0.2);
    }
    &--search{
    font-size: 1.8rem;
    padding: 10px 20px;
    @include respond(tab-port){
        font-size: 3rem !important;
        padding: 0.5rem;
    }
    @include respond(phone){
        font-size: 4rem !important;
        padding: 2rem !important;
    }
    }
    padding: 10px 20px;
    background-color: $light-blue;
    border-radius: 200px;
    border: none;
    color: white;
    font-size: 1.7rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    cursor:pointer;
    transition: all .3s;
    @include respond(tab-port){
       font-size: 2.5rem;
    padding: 5px 20px;
     }
     @include respond(phone){
         padding:  1rem 3rem;
         font-size: 3rem;
     }
    &:hover{
        background-color: white;
        color: $light-blue;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.1);
    }

    &:active{
        background-color: $red-ish;
        color: white;
        box-shadow: none;
        transform: scale(0.8);
    }
}


.inner
{
    display: inline-block;
}

#button{
    &--login{
        & a{
            color: white;
        }
        margin-right: 15px;
        @include respond(tab-port){
            margin-right: 0;
            margin-bottom: 20px;
        }
        &:hover{
            & a{
                color: $light-blue !important;
            }
            background-color: $white !important;
        }
    }
}